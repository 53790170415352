/* src/components/LoginPrompt.css */
.login-prompt {
  position: fixed;
  width: 300px;
  top: 20px;
 
  justify-content: center;
  align-items: center;
}

.green {
  color: #023602;
}

.login-prompt-content {
  background: rgb(202, 116, 66);
  padding: 20px;
  display: block;
  color: black;
  border-radius: 5px;
  text-align: center;
}


.error_message {
  color: red;
  font-size: 15px;
  font-style: italic;
}
.facebook_signin_button, .google_signin_button {
  margin: auto 5px;
  padding: 3px 6px;
  font-weight: bold;
  border: none;
  cursor: pointer;

}
.facebook_signin_button img, .google_signin_button img {
  width: 15px;
  height: 15px;
}

.facebook_signin_button {
  background-color: #21219b;
  color: white;
}

.google_signin_button {
  background-color: white;
  color: black;
}

.x_bold {
  position: absolute;
  right: 0;   
  color: aliceblue;
  margin: 0px;
  cursor: pointer;
  top: 0;
  font-size: 24px;
  padding: 5px 10px;
}


.login_button {
flex-direction: row;
margin: 5px auto;

}

button {
cursor: pointer;
}

.login-prompt-content h3 {
padding: 0px;
margin: 0px; 
}

input {
margin: 3px;

}

.comment_box {
height: 20px;
}

.each_comment {
margin: 5px auto;
border-bottom: 1px solid #21219b; /* Add a bottom border */
padding-bottom: 5px; /* Add padding to ensure some space inside the border */
}

.comments {
border-top: 1px solid #21219b;
margin: 5px auto;
}
.each_comment img {
width: 25px;
height: 25px;
border-radius: 15%;
}

.each_comment p {
margin: 0px;
font-style: italic;

}
 .each_reply p {
  margin: 0px;
  font-style: italic;
  font-size: medium;
  }

.each_reply h4 {
margin: 2px;
font-size: medium;
}

.each_reply {
background-color: #f1f3ef ;
padding: 2px 3px;
border-radius: 10px;
margin: 1px;
}

.each_comment {
background-color: #bdc9b3 ;
padding: 1px 2px;
border-radius: 10px;
}

.id_head {
display: flex;
flex-direction: row;
margin: auto;
max-height: 70px;
padding: 0%;

}

.id_head img, .id_head h5 {
margin: auto 1px;
font-size: medium;
}

.bold_comment {
  font-weight: bold;
}

.comment_date {
  font-size: small;
  
}


.signup_info {
  font-size: medium;
  color: black;
}

.auth_container {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
  background-color: #cde7b3;
  width: 300px;
}